
import { defineComponent } from "vue";
import HeaderSection from "../HeaderSection.vue";
import { TopicType } from "@toppick/common/build/interfaces";
import "../../styles.css";

interface PickerItem {
  title: string;
  image: string;
  query: string;
  type: TopicType;
}
export const pickerItems: PickerItem[] = [
  {
    image: require("@/assets/images/search.svg"),
    title: "All",
    query: "all",
    type: TopicType.Default,
  },
  {
    image: require("@/assets/images/party-hat.svg"),
    title: "Fun",
    query: "fun",
    type: TopicType.Fun,
  },
  {
    image: require("@/assets/images/speed.svg"),
    title: "Speed Friending",
    query: "speed-friending",
    type: TopicType.SpeedFriending,
  },
  {
    image: require("@/assets/images/discussion.svg"),
    title: "Discussion",
    query: "discussion",
    type: TopicType.Discussion,
  },
  {
    image: require("@/assets/images/knowledge_icon.svg"),
    title: "Knowledge",
    query: "knowledge",
    type: TopicType.Knowledge,
  },
];

export default defineComponent({
  emits: ["onDelete", "onShowAuthModal"],
  components: { HeaderSection },
  data() {
    return {
      currentItem: pickerItems[0] as PickerItem,
      pickerItems,
    };
  },
  methods: {
    onClickPickerItem(index: number) {
      this.currentItem = pickerItems[index];
    },
    goToPicker() {
      this.$router.push({
        name: "TopicPickerPlay",
        query: { type: this.currentItem.type },
      });
    },
  },

  computed: {
    currentPickerDescription(): string {
      switch (this.currentItem.type) {
        case TopicType.Fun:
          return "As much as it's important to find out more about the person you're conversing with, sometimes it's better to talk about simple, lighthearted, and even silly subjects that don't necessarily hold any deep meaning.When the other person has had a long day and isn't feeling up to discussing more serious subjects, that's when these topics are most helpful, as they give you the chance to cheer your new friend up.";
        case TopicType.SpeedFriending:
          return "To increase your chances of becoming friends with someone, you will often need to give them a chance to tell you about themselves. The topics here include a wide variety of questions that allow the other person to describe their past experiences, their likes and dislikes, what their goals in life are, and what they do everyday to achieve those goals.";
        case TopicType.Discussion:
          return "The topics found here will very often involve the other person's beliefs and opinions. Therefore, you should keep a few things in mind: Always give them the chance to say everything they have to say about the question asked, pay attention to what they're saying, keep an open mind, and be respectful. If you do these things, you're almost guaranteed to gain their trust, even if you end up disagreeing with them.";

        case TopicType.Knowledge:
          return "The topics found here are concerned with fact more than anything else. While they may not be the most conversable topics, you shouldn't dismiss them, as there is a massive amount of knowledge found in each one of them and that can be highly beneficial to you and the person you're talking with.";

        default:
          return "Besides trying our best to make sure that our library contains only topics that are interesting, we've also made sure that each one of those topics contains questions of different levels of difficulty. This ensures that you find several questions that you can easily use inside every topic, regardless of what your level of experience in the language is.";
      }
    },
  },
});
